<template>
    <!-- <main-container> -->
        <div class="mobileContainer">
            <header-container>
                <booking-success-header />
            </header-container>
            <div class="bodyContainer">
                <div style="width: 100%; height: 100%; background-color: white; margin-bottom: 20px;">
                <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 4px; margin-top: 50px;">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/successIconPoppers.gif" style="width: 85px;">
                    <div style="font-size: 16px; font-weight: 600;">Slot Booked Successfully!</div>
                    <div style="font-size: 14px; font-weight: 400; color: #4f4f4f;">Your slot has been booked Successfully.</div>
                    <div style="font-size: 14px; font-weight: 400; color: #4f4f4f;">Booking Id: <span style="font-size: 14px; font-weight: 500; color: #333333;">{{ this.bookingID }}</span></div>
                    <div class="TokenContainer">
                        <div style="color: #1467bf; font-weight: 600; font-size: 14px;">Token No:</div>
                        <div style="background-color: #1467bf;  border-radius: 50%; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; color: white; font-weight: 600;">{{ this.bookingInfo.tokenNumber }}</div>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 20px; ">
                    <div style="font-size: 14px; font-weight: 600; margin-bottom: 10px;">Appointment Details</div>
                    <doctor-info-component :bookingInfo="this.bookingInfo" @toggleViewDoctorDialog="toggleViewDoctorDialog"/>
                </div>
                <patient-info-component :bookingInfo="this.bookingInfo"/>
                <div style="margin-top: 100px;"></div>
                </div>
            </div>
            <div class="bottomBar">
                <div @click="goToPage('OPDHome')" style="display: flex; justify-content: center; align-items: center; padding: 8px; border: 1px solid #48acef; width: 30%; border-radius: 8px; cursor: pointer;">
                    <div style="color: #48acef;" >Home</div>
                </div>
                <div @click="goToPage('listOPDAppointmentsPage')" style="display: flex; justify-content: center; align-items: center; padding: 8px; border: 1px solid #48acef; background: #48acef; width: 60%; border-radius: 8px; cursor: pointer;">
                    <div style="color: #fff;" >My Slots</div>
                </div>
            </div>
            <div v-if="viewDoctorModel" transition="slide-y-transition">
                <view-doctor-component @toggleViewDoctorDialog="toggleViewDoctorDialog" :doctorInfo="doctorData" :modelValue="viewDoctorModel"/>
            </div>    
        </div>
    <!-- </main-container> -->
</template>

<script>
import axios from "axios";
import BodyContainer from '../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import MainContainer from '../../../templates/mainContainer.vue';
import hospitalBanner from '../componentUsedInPage/viewHospitalPageComponents/hospitalBannerComp.vue';
import SelectSpecialityAndSearch from '../componentUsedInPage/viewHospitalPageComponents/selectSpecialityAndSearch.vue';
import ViewParticalurHospitalHeader from '../componentUsedInPage/viewHospitalPageComponents/viewParticalurHospitalHeader.vue';
import ViewParticularDoctorComp from '../componentUsedInPage/viewHospitalPageComponents/viewParticularDoctorComp.vue';
import HospitalHeaderComp from '../componentUsedInPage/opdHomePage/hospitalHeaderComp.vue';
import SelectRegionAndSeachComp from '../componentUsedInPage/opdHomePage/selectRegionAndSeachComp.vue';
import SingleHospitalComp from '../componentUsedInPage/opdHomePage/singleHospitalComp.vue';
import { axios_auth_instance_customer, axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
import BookingSuccessHeader from '../componentUsedInPage/bookingSuccessPage/bookingSuccessHeader.vue';
import DoctorInfoComponent from '../componentUsedInPage/bookingSuccessPage/doctorInfoComponent.vue';
import PatientInfoComponent from '../componentUsedInPage/bookingSuccessPage/patientInfoComponent.vue';
import ViewDoctorComponent from '../componentUsedInPage/commonAcrossPages/viewDoctorComponent.vue';



export default {
  components: {BodyContainer, HeaderContainer, HospitalHeaderComp, SelectRegionAndSeachComp, SingleHospitalComp, ViewParticalurHospitalHeader, MainContainer, hospitalBanner, SelectSpecialityAndSearch, ViewParticularDoctorComp, BookingSuccessHeader, DoctorInfoComponent, PatientInfoComponent, ViewDoctorComponent },
    name: 'HospitalBookingSuccessPage',
    data: function() {
        return {
            token: null,
            viewDoctorModel: false,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            allDetailsDiv: null,
            dependent_members: [],
            currentCustomer: null,
            doctorList: [],
            bookingID: null,
            bookingInfo: {},
            doctorInfo: null,
            doctorData: null,
        };
    },
    computed: {
        showHospitalLoader () {
            return this.$store.state.particularHospitalState.fetchingData;        
        },
        getDocotorList() {
            return this.$store.state.particularHospitalState.shownDoctorList || [];        
        }
    },
    async mounted() {
        if (!window.xm)
            this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        this.disableBackNavigation();
        this.bookingID = this.$route.params.bookingID;
        try {
            let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/bookingDetails/${this.bookingID}`);
            this.bookingInfo = apiResponse.data.bookingDetails;
        } catch(error) {
            console.log(error);
        }

        this.userPageViewTable();
    },

    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'OPDBookSuccessPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'OPDBookSuccessPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        async toggleViewDoctorDialog(value) {
            if(value) {
                try {
                    if (this.doctorData == null) {
                        let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.bookingInfo.doctorInfo.doctorID}`);
                        this.doctorData = apiResponse.data.doctorDetails;
                    }
                    this.viewDoctorModel = true;
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.viewDoctorModel = false;
            }
        },
        disableBackNavigation() {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        this.$router.go(-1); // Prevent back navigation
      };
    },
        goToPage(value) {
            this.$router.push({
                name: value,
            });
        },
    },
};

</script>
<style  scoped>
.TokenContainer {
    width: 150px;
    height: 44px;
    padding: 8px 8px 8px 16px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 16px;
    background: #1467BF1A;
}
.mobileContainer {
    margin: auto;
    width: 425px;
    background-color: white;
}
.bodyContainer {
    width: 100%;
    padding: 0 16px;
    height: 94vh;
    padding-bottom: 200px  !important;
    overflow: auto;
    background-color: white;
}
.bottomBar {
    position: absolute;
    bottom: 0;
    /* background: ; */
    box-shadow: 0px -4px 8px 0px #0000000A;
    width: 100%;
    max-width: 425px;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-evenly;
    z-index: 1;
    background: white;
}
.bottomSheetContainer {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 70vh;
    background: #fff;
    border: 1px solid #ebebe8;
    border-radius: 16px;
    max-width: 425px;
    max-height: 70vh;
    overflow-y: scroll;
    z-index: 1000;
}
.doctorBodyContainer {
    padding: 32px 16px 32px 16px;
}
.experienceBox {
    padding: 8px 16px 8px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.bottomSheetHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8ebeb;
}
@media screen and (max-width: 425px){
.mobileContainer {
    width: 100%;
}
}
</style>